import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useState } from "react";
import { Executor } from "./Executor";
export var NotifyType;
(function (NotifyType) {
    NotifyType[NotifyType["Success"] = 0] = "Success";
    NotifyType[NotifyType["Error"] = 1] = "Error";
})(NotifyType || (NotifyType = {}));
export var useExecutor = function (fetch, 
// FetchSignature<P>,
params, notify, walletId, signAllTransactions, solanaRpcHost) {
    var _a = __read(useState(false), 2), isExecuting = _a[0], setIsExecuting = _a[1];
    var onClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var executor;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(walletId && signAllTransactions)) return [3 /*break*/, 2];
                    executor = new Executor(fetch, params, signAllTransactions, notify, solanaRpcHost);
                    setIsExecuting(true);
                    return [4 /*yield*/, executor.execute()];
                case 1:
                    _a.sent();
                    setIsExecuting(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [walletId, signAllTransactions, params, fetch]);
    return { isExecuting: isExecuting, onClick: onClick };
};
